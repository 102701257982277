import axios from "axios";
import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";
import {Link ,Navigate,useNavigate,useParams } from "react-router-dom";
import { useEffect, useState } from "react";





export const Attendanceupdate = () => {


  const [id,setId]=useState("");

 const [present,setPresent]=useState("");
///------------------------------------------------------------------------------------------
 
const navigate = useNavigate();



////-----------------------------------------------------------------------------------




const postAPI = () => {

  const formData = new FormData();


  formData.append('Id',id);

  formData.append('Present', present);
 
     

      
    
     
  try {
    const response =  axios.post("https://peapi.microtechsolutions.co.in/php/setpresent.php", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // Handle the response
    console.log(response.data);
    alert("Data Update Successfully !!!")
    navigate('/listAttendance');
  } catch (error) {
    console.error('Error uploading image:', error);
  }
 
};
       

    //-----------------------------------------------------------------------------------------------------------------




  const {Id}=useParams();

  
useEffect(() => {
  const getcategory = async () => {
    const res = await fetch("https://peapi.microtechsolutions.co.in/php/getidatten.php?Id=" + Id);
    const getdata = await res.json();

 
    studentdata(getdata);

  };

  getcategory();

}, []);




const studentdata=(getdata)=>{
  {
    getdata.map((item)=>(

    setId(item.Id),


    setPresent(item.Present)
    
    
  
  
  
    ))
  }
  
  }











  return (
    <div className="d-flex">
      <div>
        <Sidebar />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
          height: "100vh",
          overflowY: "hidden",
        }}
      >
        <Navbar />
        <div style={{ height: "100%" }}>
          <div
            style={{
              padding: "20px 5%",
              height: "calc(100% - 64px)",
              overflowY: "scroll",
              background: "whitesmoke",
            }}
          >
            <div
              style={{
                display: "grid",
                background: "white",
                gridTemplateColumns: "repeat(1, minmax(200px, 1200px))",
              }}
            >


              <div className="page-wrapper" >
                <div className="content container-fluid" >
                  <div className="page-header" style={{ backgroundColor: '#0b6fa1' }}>
                    <div className="row align-items-center" >
                      <div className="col-sm-12"  >
                        <div className="page-sub-header" >
                          <h3 className="page-title" style={{ color: "white", textAlign: 'center' }} >Attendance Update !!</h3>
                          <Link exact to="/listAttendance" activeClassName="activeClicked">
                            <button style={{ background: "#D9468D", color: 'white', borderColor: 'CaptionText', borderRadius: '8px', boxSizing: "border-box" }} >List</button>
                          </Link>


                        </div>
                      </div>
                    </div>
            
                    <div className="row" >
                      <div className="col-sm-12" >
                        <div className="card comman-shadow" style={{ backgroundColor: '#55a6cf' }}>
                          <div className="card-body">
                            <form>
                              <div className="row">


                             <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>
                                      ID <span className="login-danger">*</span>
                                    </label>
                                    <input 
                                    
                                      value={Id}
                                 disabled
                                    
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter  Enquiry No"
                                    />
                                  </div>
                                </div>


                              
                           


                                <div className="col-12 col-sm-4">
                  <div className="form-group local-forms">
                    <label>Present  <span class="text-danger">*</span></label>
                    <select   value={present}  onChange={e => setPresent(e.target.value)} className="form-control select">

                      <option>Please Select  </option>
                      <option value='1'  > Present </option>
                      <option value='0'>Absent</option>
                    




                    </select>



                  </div>
                </div>



                                <div className="col-12">
                                  <div className="student-submit">
                                    <button onClick={postAPI} className="btn btn-primary">
                                      Submit
                                    </button>






                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>


              <footer className="mx-auto my-3 text-center">
                <small>&copy; Microtech, 2023. All rights reserved.</small>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>



  );
};

