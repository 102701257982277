
import React, { useState, useEffect } from "react";
import { CDBTable, CDBTableHeader, CDBTableBody } from "cdbreact";
import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";
import { Pagination, Form, Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios  from "axios";
import { toast } from "react-toastify";

import { TfiPencilAlt } from "react-icons/tfi";

const ListAttendance = () => {
  
const navigate=useNavigate();


  // update Attendance 

const updateAttendance=(Id)=>{


  navigate('/AttenstudentUpdate/' +Id )

}


 ///-------------------------------------------
 

    const [category, setCategory] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);


  



    //List By Date
    const [show, setShow] = useState();

    const [attendancedate, setAttendancedate] = useState("");
    const [branchid, setBranchId] = useState();
    const [batchid, setBatchId] = useState();
   
  
    const fetchDate = async () => {
  
      const response = await axios.get('https://peapi.microtechsolutions.co.in/php/getattendate.php?AttenDate=' + attendancedate + '&BatchId=' + batchid + '&BranchId=' + branchid)
  
        .then((response) => {
          // console.log((response.data));
          checkdate(response.data);
        })
  
        .catch((error) => {
          console.log(error);
        });
    }
  
  
  
    const checkdate = (txt) => {
      let data = txt
      setCategory(data);
      let count = Object(data).length
      if (count > 1) {
        setShow(false);
      } else {
        setShow(true);
      }
  
    }




    
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems  = category.slice(indexOfFirstItem, indexOfLastItem);
   

  
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

   
 
    const handleItemsPerPageChange = (e) => {
      const newItemsPerPage = parseInt(e.target.value, 10);
      if (!isNaN(newItemsPerPage) && newItemsPerPage > 0) {
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1); 
      }
    };
  



    /////////////////////Branch Batch
    
  const [Branch, setBranch] = useState([]);


  const getBranchData = () => {
    axios.get('https://peapi.microtechsolutions.co.in/php/getbranchmst.php').then((response) => {
      const result = response.data;
      setBranch(result);
    }).catch((error) => {
      toast.error('Error while fetching product details');
    });
  }


  useEffect(() => {

    getBranchData();



  }, []);


  

  const [Batch, setBatch] = useState([]);


  const getBatcheData = () => {
    axios.get('https://peapi.microtechsolutions.co.in/php/getbatchmst.php').then((response) => {
      const result = response.data;
      setBatch(result);
    }).catch((error) => {
      toast.error('Error while fetching product details');
    });
  }


  useEffect(() => {

    getBatcheData();



  }, []);




  return (
    <div className="d-flex">
      <div>
      <Sidebar />
      </div>
      <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
        <Navbar   />
      
        <div style={{ height: "100%" }}>
          <div style={{ padding: "20px 5%", height: "calc(100% - 80px)", overflowY: "scroll" }}>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(1, minmax(200px, 1920))" }}>

            <Link exact to="/attendence" activeClassName="activeClicked">
                            <button  style={{ marginLeft: '10px' }} className="btn btn-info" >Back</button>
                          </Link>
                
               
             
              <div className="mt-5">
                <h4 className="font-weight-bold mb-3">STUDENT Attendance</h4>
                <Form>
                <Form>
        <Row className="mb-3">
          <Col xs="auto">
            <Form.Group controlId="formItemsPerPage">
              <Form.Label>Items per Page</Form.Label>
              
              <Form.Control
                type="number"
               min={10}
                placeholder="Enter items per page"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
                
                <div class="row">


<div className="col-12 col-sm-4">
  <div className="form-group local-forms calendar-icon">
    <label>
      Date <span className="login-danger">*</span>
    </label>
    <input
      onChange={e => setAttendancedate(e.target.value)}

      className="form-control datetimepicker"
      type="date"
      placeholder="DD-MM-YYYY"
    />
        </div>
        </div>
        <div className="col-12 col-sm-4" >
                                     <div className="form-group local-forms">
                                    <label>
                                      Branch Name <span className="login-danger">*</span>
                                    </label>
                                    <select className="form-select" aria-label="Default select example" value={branchid} onChange={e => setBranchId(e.target.value)} >
                                      <option selected Value="">Choose Branch</option>
                                      {Branch.map((branch) => {
                                        return (
                                          <>
                                            <option key={branch.Id} value={branch.Id}>{branch.BranchName}</option>
                                          </>
                                        )
                                      })}
                                    </select>

                                  </div>
                                </div>

                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>
                                      Batch Name <span className="login-danger">*</span>
                                    </label>
                                    <select className="form-select" aria-label="Default select example" value={batchid} onChange={e => setBatchId(e.target.value)}>
                                      <option selected Value="">Choose Batch</option>
                                      {Batch.map((batch) => {
                                        return (
                                          <>
                                            <option key={batch.Id} value={batch.Id}>{batch.BatchName}</option>
                                          </>
                                        )
                                      })}
                                    </select>

                                  </div>
                                </div>




<div class="col-12" style={{ paddingTop: "10px" }}>
  <div class="student-submit">
    <button class="btn btn-success" onClick={fetchDate} >Submit</button>
  </div>
</div>
</div>

      
      </Form>



 
                {(() => {

             if (show === true) {
              return (

              <h1>Data Not Present</h1>

                     )
                }

               else {
                 return (
                  <CDBTable responsive>
                  <CDBTableHeader color="dark">
                    <tr>
                      <th>ID</th>
                     
                    
                  
                      <th>Student_Name</th>
                      <th>Present</th>
                     
                  

                    </tr>
                  </CDBTableHeader>
                  <CDBTableBody>

               

                    { category 

                      .map((getcate) => (

                        <tr key={getcate.Id}>


                        
                       
                        <td>{getcate.StudentId}</td>
                     
                     
                
                        <td>{getcate.StudentName}</td>
                        <td>{getcate.Present}</td>
                       
                       
                    




                                
                          


                                <td>
                            <button  onClick={()=>{updateAttendance(getcate.Id)}} style={{ marginLeft: '10px' }} className="btn btn-success" ><TfiPencilAlt /></button>
                        
                                </td>
                          
                                
                                    
                      </tr>
                    ))}


                  </CDBTableBody>
                </CDBTable>
                
                    );
                    }
                     })()}




     


               

              </div>
            

            </div>
            <Row className="justify-content-center">
        <Col xs="auto">
          <Pagination>
            {Array.from({ length: Math.ceil(category.length / itemsPerPage) }, (_, index) => (
              <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => paginate(index + 1)}>
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </Col>
      
      </Row>
            <footer className="mx-auto my-3 text-center">
              <small>&copy; Microtech, 2023. All rights reserved.</small>
            </footer>
       
          </div>

        </div>
      </div>
    </div>
  );
};


export default ListAttendance;