
import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login/Login';
import Home from './pages/Home';

import EnquiryView from "./pages/Enquiry/EnquiryView";
import  {EnquiryUpdate}  from "./pages/Enquiry/EnquiryUpdate";
import {Enquiry} from './pages/Enquiry/Enquiry';
import EnquiryList from "./pages/Enquiry/EnquiryList";


import { Registration } from './pages/StudentRegistration/Registration';
import ListRegister from './pages/StudentRegistration/ListRegister';
import StudentView from './pages/StudentRegistration/StudentView';
import  {StudentUpdates}  from './pages/StudentRegistration/StudentUpdates';

import ListAttendance from './pages/Attendence/ListAttendance';
import { Attendanceupdate } from './pages/Attendence/Attendanceupdate';
import {Attendence} from "./pages/Attendence/Attendence";


import Fees from './pages/Fees/Fees';


import { Coach } from "./pages/Demo/Coach";
import { Branch } from "./pages/Branch";




import {  Subject } from "./pages/Subject";

import BatchList from "./pages/Batch/BatchList";
import DemoType from "./pages/Demo/DemoType";





import { Batch } from './pages/Batch/Batch';
import ListFees from './pages/Fees/ListFees';
import UpdateFees from './pages/Fees/UpdateFees';







const Path = () => {
  return (
    <Router>
      <Routes>
    
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home/>} />


        <Route path="/enquiryView/:Id" element={ <EnquiryView/>}/>
        <Route path="/enquiryUpdate/:Id" element={ <EnquiryUpdate/>}/>
        <Route path="/listEnquiry" element={ <EnquiryList/>}/>
        <Route path="/enquiry" element={ <Enquiry/>} />


        
              {/* //Student Registration */}
              <Route path="/registration" element={<Registration/>}/>
        <Route path="/listregistration" element={<ListRegister/>}/>
        <Route path="/studentView/:Id" element={<StudentView/>}/>
        <Route path="/studentUpdate/:Id" element={<StudentUpdates/>}/>






        
        <Route path="/attendence" element={<Attendence/>}/>
        <Route path="/listAttendance" element={<ListAttendance/>}/>
        <Route path="/AttenstudentUpdate/:Id" element={<Attendanceupdate/>}/>
     
   
        <Route path="/fees" element={<Fees/>}/>
        <Route path="/listFees" element={<ListFees/>}/>
        <Route path="/updateFees/:Id" element={<UpdateFees/>}/>


        
        <Route path="/branch" element={<Branch/>}/>
        <Route path="/batch" element={ <Batch/>}/>
        <Route path="/coach" element={ <Coach/>}/>
     
        <Route path="/subject" element={ <Subject/>}/>
        <Route path="/batchlist" element={ <BatchList/>}/>

    
        <Route path="/type" element={ <DemoType/>}/>
     
 







               
      </Routes>
    </Router>
  );
};

export default Path;
