import React, { useState } from 'react'

export default function BatchList() {


   const [data , setData] = useState()
 
    
    fetch('https://peapi.microtechsolutions.co.in/php/getsubjmst.php')
      .then(response => response.text())
      .then(result => setData(result))
      .catch(error => console.log('error', error));



  return (
    <div>
   {
    <h1>{data}</h1>
  

    }
   
      
    



 </div>

  )
}