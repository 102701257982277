
import axios from "axios";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";







export const Fees = () => {



  const [studentid, setStudentId] = useState("");
  const [feesdate, setFeesDate] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState('');
  const [fees, setFees] = useState("");



  const [branch, setBranch] = useState("");
  const [batch, setBatch] = useState("");







  const postAPI = () => {
    let config = {
      method: 'post',
      mode: "cors",

      maxBodyLength: Infinity,
      url: "https://peapi.microtechsolutions.co.in/php/postfees.php",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: {

        'StudentId': studentid,
        'FeesDate': feesdate,

        "Month": month,
        "Year": year,
        "Fees": fees,

      }
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        alert("Data Send Successfully !!!")
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);

      });

    //-----------------------------------------------------------------------------------------------------------------

  }



  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    // Fetch data from the PHP API
    fetch('https://peapi.microtechsolutions.co.in/php/getregistration.php')
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setFilteredData(data);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    // Filter data based on search term
    const filtered = data.filter((item) => item.StudentName && item.StudentName.toLowerCase().includes(term));
    setFilteredData(filtered);
  };




  // useEffect(() => {
  //   const getcategory = async () => {
  //     const res = await fetch("https://peapi.microtechsolutions.co.in/php/getfeesbystd.php?Id=" + studentid);
  //     const getdata = await res.json();


  //     studentdata(getdata);
  //     console.log(studentdata(getdata));

  //   };

  //   getcategory();

  // }, []);




  const fetchStudentData = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/getfeesbystd.php?Id=" + studentid); // Replace with your API endpoint
      console.log(response.data)
      fetchingbrbat(response.data);

    } catch (error) {
      console.error('Error fetching student data', error);
     
    }
  };


  const fetchingbrbat=(data)=>{
    {
      data.map((item)=>(
        setBatch(item.BatchName),
        setBranch(item.BranchName)
           
      ))
    }
    
    }

  return (
    <div className="d-flex">
      <div>
        <Sidebar />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
          height: "100vh",
          overflowY: "hidden",
        }}
      >
        <Navbar />
        <div style={{ height: "100%" }}>
          <div
            style={{
              padding: "20px 5%",
              height: "calc(100% - 64px)",
              overflowY: "scroll",
              background: "whitesmoke",
            }}
          >
            <div
              style={{
                display: "grid",
                background: "white",
                gridTemplateColumns: "repeat(1, minmax(200px, 1200px))",
              }}
            >


              <div className="page-wrapper" >
                <div className="content container-fluid" >
                  <div className="page-header" style={{ backgroundColor: '#0b6fa1' }}>
                    <div className="row align-items-center" >
                      <div className="col-sm-12"  >
                        <div className="page-sub-header" >
                          <h3 className="page-title" style={{ color: "white", textAlign: 'center' }} >Fees</h3>
                          <Link exact to="/listFees" activeClassName="activeClicked">
                            <button style={{ background: "#D9468D", color: 'white', borderColor: 'CaptionText', borderRadius: '8px', boxSizing: "border-box" }} >List</button>
                          </Link>


                        </div>
                      </div>
                    </div>
                    <div className="row" >
                      <div className="col-sm-12" >
                        <div className="card comman-shadow" style={{ backgroundColor: '#55a6cf' }}>
                          <div className="card-body">
                            <form>
                              <div className="row">




                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>
                                      Student Name <span className="login-danger">*</span>
                                    </label>
                                    <div style={{ position: 'relative', display: 'inline-block' }}>
                                      <input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchTerm}
                                        onChange={handleSearch}
                                        style={{
                                          padding: '8px',
                                          border: '1px solid #ccc',
                                          borderRadius: '4px',
                                          marginRight: '10px',
                                        }}
                                      />
                                      <select
                                        style={{
                                          padding: '8px',
                                          border: '1px solid #ccc',
                                          borderRadius: '8px',
                                          cursor: 'pointer',
                                          marginRight: "10px",

                                        }}
                                        onChange={e => setStudentId(e.target.value)}
                                        value={studentid}


                                      >
                                        {filteredData.map((item) => (
                                          <option key={item.Id} value={item.Id}>
                                            {item.StudentName}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                             





                                <div class="col-12" style={{ paddingTop: "10px" }}>
                                  <div class="student-submit">
                                    <button class="btn btn-success" onClick={fetchStudentData} >Submit</button>
                                  </div>
                                </div>

                             









                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>
                                      Batch ID  <span className="login-danger">*</span>
                                    </label>

                                    <input

                                          value={batch} 
                                      className="form-control"
                                     

                                    />
                                  </div>
                                </div>



                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>
                                      Branch Id  <span className="login-danger">*</span>
                                    </label>

                                    <input
                                    value={branch}
                                  
                                      className="form-control"
                                    />
                                  </div>
                                </div>







                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>Date </label>
                                    <input

                                      onChange={e => setFeesDate(e.target.value)}
                                      className="form-control"
                                      type="date"
                                      placeholder="Enter Date "
                                    />
                                  </div>
                                </div>

                              
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>Month </label>
                                    <select    className="form-control select"  onChange={e => setMonth(e.target.value)} >

                                      <option>Please Select Month </option>
                                      <option value='1'  > January </option>
                                      <option value='2'>February</option>
                                      <option value='3'>March</option>
                                      <option value='4'>April</option>
                                      <option value='5'>May</option>
                                      <option value='6'>June</option>
                                      <option value='7'>July</option>
                                      <option value='8'>August</option>
                                      <option value='9'>September</option>
                                      <option value='10'>October</option>
                                      <option value='11'>November</option>
                                      <option value='12'>December</option>
                                 




                                    </select>



                                  </div>
                                </div>



                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>Year </label>
                                    <input

                                      onChange={e => setYear(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter Year"
                                    />
                                  </div>
                                </div>



                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>Fees </label>
                                    <input

                                      onChange={e => setFees(e.target.value)}
                                      className="form-control"
                                      type="Number"
                                      placeholder="Enter Fees"
                                    />
                                  </div>
                                </div>



                                <div className="col-12">
                                  <div className="student-submit">
                                    <button style={{ marginTop: "10PX" }} onClick={postAPI} className="btn btn-primary">
                                      Submit
                                    </button>






                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>


              <footer className="mx-auto my-3 text-center">
                <small>&copy; Microtech, 2023. All rights reserved.</small>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>



  );
};

export default Fees;
