import "../backgr.css";



function DemoType() {





  return (

    <form style={{padding:"50px", backgroundColor: "White"}}>
   <div class="input-group">
   <input class='input' required type="text" id="username" /> 
   <label class="label" for="username">Username</label>

   </div>
   </form>
  )
}

export default DemoType
