import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";
import { Link, useNavigate } from "react-router-dom";






export const Enquiry = () => {



  
  const navigate= useNavigate("");
  const [enquiryNo, setenquiryNo] = useState("");
  const [enquiryDate, setenquiryDate] = useState("");
  const [student_name, setStudentName] = useState("");


  const [reference, setReference] = useState("");
  const [parent_name, setParentName] = useState("");
  const [parents_job, setParentJob] = useState("");
  const [address, setAddress] = useState("");

  const [mobile_no, setMobileNo] = useState("");

  const [school_name, setSchoolName] = useState("");

  const [school_timing, setSchoolTiming] = useState("");




  const [email_ID, setEmailId] = useState("");


  const [MCA_ID, setMCA_Id] = useState("");
  const [FIDE_ID, setFIDE_Id] = useState("");
  const [AICF_ID, setAICF_Id] = useState("");

  const [whatsappNo, setWhatsappNo] = useState("");



  const [city, setCity] = useState("");
  const [state, setStates] = useState("");

  const [batchmst, setBatchmst] = useState("");
  const [branchmst, setBranchmst] = useState("");

  const [enquiry_mode, setEnquiryMode] = useState("");
  const [enquirytype, setEnquiryType] = useState("");

  const [stdphoto, setstdphoto] = useState("null");
  const [birthphoto, setBirthPhoto] = useState("null");
  const [adharcard, setAdharcard] = useState("null");


  const handlephotochange = (e) => {
    setstdphoto(e.target.files[0]);
  }


  const handlebirthphotochange = (e) => {
    setBirthPhoto(e.target.files[0]);
  }
  const handleaadharphotochange = (e) => {
    setAdharcard(e.target.files[0]);
  }





  const postAPI = () => {

    const formData = new FormData();
    formData.append('EnquiryNo', enquiryNo);
    formData.append('EnquiryDate', enquiryDate);
    formData.append('StudentName', student_name);
    formData.append('Reference', reference);
    formData.append('ParentsName', parent_name);
    formData.append('ParentsJob', parents_job);
    formData.append('Address', address);
    formData.append('CityId', city);
    formData.append('StateId', state);
    formData.append('MobileNo', mobile_no);

    formData.append('SchoolName', school_name);
    formData.append('SchoolTiming', school_timing);
    formData.append('EnquiryModeId', enquiry_mode);
    formData.append('EnquiryTypeId', enquirytype);
    formData.append('EmailId', email_ID);
    formData.append('PrefferedTimingId',branchmst );
    formData.append('PrefferedBatchId', batchmst);
    formData.append('MCAId', MCA_ID);
    formData.append('FIDEId', FIDE_ID);
    formData.append('AICFId', AICF_ID);




    formData.append('WhatsappNo', whatsappNo);
    formData.append('StudentPhoto', stdphoto);
    formData.append('BirthCertificate', birthphoto);
    formData.append('AddharCard', adharcard);

    try {
      const response = axios.post('https://peapi.microtechsolutions.co.in/php/postenquiry.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log(response.data);
       alert("Data Successfully Send")
      navigate('/listEnquiry');
 
    } catch (error) {
      console.error('Error uploading image:', error);
    }

  };




  const [cities, setcities] = useState([]);



  const getCityData = async () => {
    const url = 'https://peapi.microtechsolutions.co.in/php/getcity.php';
    let result = await fetch(url);
    result = await result.json();
    setcities(result);
    console.log("data Fetch Successfully")
    console.log(cities)
  }




  const [State, setState] = useState([]);
  const getStateData = async () => {
    const url = 'https://peapi.microtechsolutions.co.in/php/getstate.php';
    let result = await fetch(url);
    result = await result.json();
    setState(result);
    console.log("data Fetch Successfully")
    console.log(State)
  }


  const [Batch, setBatch] = useState([]);
  const getBatcheData = async () => {
    const url = 'https://peapi.microtechsolutions.co.in/php/getbatchmst.php';
    let result = await fetch(url);
    result = await result.json();
    setBatch(result);
    console.log("data Fetch Successfully")
    console.log(State)
  }


  const [Branch, setBranch] = useState([]);
  const getBranchData = async () => {
    const url = 'https://peapi.microtechsolutions.co.in/php/getbranchmst.php';
    let result = await fetch(url);
    result = await result.json();
    setBranch(result);
    console.log("data Fetch Successfully")
    console.log(State)
  }


  const [EMode, setEMode] = useState([]);
  const getBatchMode = async () => {
    const url = 'https://peapi.microtechsolutions.co.in/php/getbatchmod.php';
    let result = await fetch(url);
    result = await result.json();
    setEMode(result);
    console.log("data Fetch Successfully")
    console.log(EMode)
  }


  const [EType, setEType] = useState([]);
  const getBatchType = async () => {
    const url = 'https://peapi.microtechsolutions.co.in/php/getbatchtyp.php';
    let result = await fetch(url);
    result = await result.json();
    setEType(result);
    console.log("data Fetch Successfully")
    console.log(EType)
  }



  useEffect(() => {
    setTimeout(() => {
      getBatchType();


    }, 1000);
  }, []);


  useEffect(() => {
    setTimeout(() => {
      getBatchMode();


    }, 1000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getBranchData();


    }, 1000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getBatcheData();


    }, 1000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getStateData();


    }, 1000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getCityData();


    }, 1000);
  }, []);


  return (
    <div className="d-flex">
      <div>
        <Sidebar />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
          height: "100vh",
          overflowY: "hidden",
        }}
      >
        <Navbar />
        <div style={{ height: "100%" }}>
          <div
            style={{
              padding: "20px 5%",
              height: "calc(100% - 64px)",
              overflowY: "scroll",
              background: "whitesmoke",
            }}
          >
            <div
              style={{
                display: "grid",
                background: "white",
                gridTemplateColumns: "repeat(1, minmax(200px, 1200px))",
              }}
            >
              <>

                <div className="page-wrapper" >
                  <div className="content container-fluid" >
                    <div className="page-header" style={{ backgroundColor: '#0b6fa1' }}>
                      <div className="row align-items-center" >
                        <div className="col-sm-12"  >
                          <div className="page-sub-header" >
                            <h3 className="page-title" style={{ color: "white", textAlign: 'center' }} >ENQUIRY ENTRY</h3>

                          </div>


                          <Link exact to="/listEnquiry" activeClassName="activeClicked">
                            <button style={{ background: "#D9468D", color: 'white', borderColor: 'CaptionText', borderRadius: '8px', boxSizing: "border-box" }} >List</button>
                          </Link>

                        </div>
                      </div>
                    </div>
                    <div className="row" >
                      <div className="col-sm-12" >
                        <div className="card comman-shadow" style={{ backgroundColor: '#55a6cf' }}>
                          <div className="card-body">
                            <form  >
                              <div className="row">
                                <div className="col-12">

                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>
                                      Enquiry No <span class="text-danger">*</span>
                                    </label>
                                    <input
                                      onChange={e => setenquiryNo(e.target.value)}

                                      className="form-control"
                                      type="text"
                                      placeholder="Enter  Enquiry No"
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms calendar-icon">
                                    <label>
                                      Enquiry Date <span className="login-danger">*</span>
                                    </label>
                                    <input
                                      onChange={e => setenquiryDate(e.target.value)}

                                      className="form-control datetimepicker"
                                      type="date"
                                      placeholder="DD-MM-YYYY"
                                    />
                                  </div>
                                </div>


                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>
                                      Student Name <span className="login-danger">*</span>
                                    </label>
                                    <input
                                      onChange={e => setStudentName(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter Student Name"
                                    />
                                  </div>
                                </div>


                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms calendar-icon">

                                    <label className="file-upload image-upbtn mb-0">
                                      Student Photo <span class="text-danger">*</span> </label>
                                    <input onChange={handlephotochange} type="file" />



                                  </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>Reference <span class="text-danger">*</span> </label>
                                    <input
                                      onChange={e => setReference(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Reference"
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>Parents Name <span class="text-danger">*</span> </label>
                                    <input
                                      onChange={e => setParentName(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter Parents Name"
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>Parent's Job Profile <span class="text-danger">*</span> </label>
                                    <input
                                      onChange={e => setParentJob(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter Job Profile"
                                    />
                                  </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                  <div class="form-group">
                                    <label>Address<span class="text-danger">*</span></label>
                                    <input onChange={e => setAddress(e.target.value)} type="text" class="form-control" />
                                  </div>
                                </div>





                               
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>City ID </label>


                                    <select className="form-select" aria-label="Default select example" value={city}   onChange={e => setCity(e.target.value)} >
                                    <option selected Value="">Choose City</option>
                                    {cities.map((cityid) => {
                                        return (
                                            <>
                                            <option key={cityid.Id} value={cityid.Id}>{cityid.Name} </option>
                                            </>
                                        )
                                    })}
                                </select>



                                  </div>
                                </div>

                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>State ID </label>
                                    <select className="form-select" aria-label="Default select example" value={state}  onChange={e => setStates(e.target.value)} >
                                    <option selected Value="">Choose State</option>
                                    {State.map((state) => {
                                        return (
                                            <>
                                            <option key={state.Id} value={state.Id}>{state.State}</option>
                                            </>
                                        )
                                    })}
                                </select>


                                  </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>Mobile No<span class="text-danger">*</span> </label>
                                    <input
                                      onChange={e => setMobileNo(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter Mobile Number"
                                    />
                                  </div>


                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>Whatsapp No<span class="text-danger">*</span> </label>
                                    <input
                                      onChange={e => setWhatsappNo(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter Mobile Number"
                                    />
                                  </div>


                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>School name <span class="text-danger">*</span></label>
                                    <input
                                      onChange={e => setSchoolName(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter school name"
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>School Timing<span class="text-danger">*</span> </label>
                                    <input
                                      onChange={e => setSchoolTiming(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter Timing"
                                    />
                                  </div>
                                </div>






                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>
                                    Enquiry Mode <span className="login-danger">*</span>
                                    </label>
                                    <select className="form-select" aria-label="Default select example"  value={enquiry_mode} onChange={e => setEnquiryMode(e.target.value)} >
                                    <option selected Value="">Choose Mode</option>
                                    {EMode.map((mode) => {
                                        return (
                                            <>
                                            <option key={mode.Id} value={mode.Id}>{mode.Mode}</option>
                                            </>
                                        )
                                    })}
                                </select>
                                  </div>
                                </div>





                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>
                                    Enquiry Type <span className="login-danger">*</span>
                                    </label>
                                    <select className="form-select" aria-label="Default select example"  value={enquirytype} onChange={e => setEnquiryType(e.target.value)} >
                                    <option selected Value="">Choose Type</option>
                                    {EType.map((type) => {
                                        return (
                                            <>
                                            <option key={type.Id} value={type.Id}>{type.Type}</option>
                                            </>
                                        )
                                    })}
                                </select>
                                  </div>
                                </div>












                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms calendar-icon">

                                    <label className="file-upload image-upbtn mb-0">
                                      Birth Certificate Photo <span class="text-danger">*</span>   </label>
                                    <input onChange={handlebirthphotochange} type="file" />




                                  </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms calendar-icon">

                                    <label className="file-upload image-upbtn mb-0">
                                      Aadhar Card Photo<span class="text-danger">*</span>     </label>
                                    <input onChange={handleaadharphotochange} type="file" />




                                  </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>
                                      E-Mail Id <span className="login-danger">*</span>
                                    </label>
                                    <input
                                      onChange={e => setEmailId(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter Email Address"
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>
                                      Batch ID <span className="login-danger">*</span>
                                    </label>
                                    <select className="form-select" aria-label="Default select example" value={batchmst} onChange={e => setBatchmst(e.target.value)}>
                                    <option selected Value="">Choose Batch</option>
                                    {Batch.map((batch) => {
                                        return (
                                            <>
                                            <option key={batch.Id} value={batch.Id}>{batch.BatchName}</option>
                                            </>
                                        )
                                    })}
                                </select>
                                  </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>
                                      Branch Id <span className="login-danger">*</span>
                                    </label>
                                    <select className="form-select" aria-label="Default select example"  value={branchmst} onChange={e => setBranchmst(e.target.value)} >
                                    <option selected Value="">Choose Branch</option>
                                    {Branch.map((branch) => {
                                        return (
                                            <>
                                            <option key={branch.Id} value={branch.Id}>{branch.BranchName}</option>
                                            </>
                                        )
                                    })}
                                </select>
                                  </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>MCA ID<span class="text-danger">*</span> </label>
                                    <input
                                      onChange={e => setMCA_Id(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter MCA ID"
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>FIDE ID <span class="text-danger">*</span></label>
                                    <input
                                      onChange={e => setFIDE_Id(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter FIDE ID"
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>AICF ID<span class="text-danger">*</span> </label>
                                    <input
                                      onChange={e => setAICF_Id(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter AICF ID"
                                    />
                                  </div>
                                </div>



                                <div className="col-12">
                                  <div className="student-submit">
                                    <button onClick={postAPI} className="btn btn-success">

                                      Submit
                                    </button>

                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </>



            </div>
            <footer className="mx-auto my-3 text-center">
              <small>&copy; Microtech, 2023. All rights reserved.</small>
            </footer>
          </div>
        </div>
      </div>
    </div>


  );
};

