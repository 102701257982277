import axios from "axios";
import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";
import {Link ,useNavigate,useParams } from "react-router-dom";
import { useEffect, useState } from "react";





export const StudentUpdates = () => {


  const [setId,changeId]=useState("");
  const [enquiry_Id, enquiry_Idchange] = useState("");

  const [registration_no, registration_nochange] = useState("");
  const [registationdate, registationdatechange] = useState("");
  const [student_name, student_namechange] = useState("");

  const [reference, referencechange] = useState("");
  const [parent_name, parent_namechange] = useState("");
  const [parents_job, parents_jobchange] = useState("");
  const [address, addresschange] = useState("");

  const [mobile_no, mobile_nochange] = useState("");

  const [school_name, school_namechange] = useState("");

  const [school_timing, school_timingchange] = useState("");



  const [email_ID, email_IDchange] = useState("");


  const [MCA_ID, MCA_IDchange] = useState("");
  const [FIDE_ID, FIDE_IDchange] = useState("");
  const [AICF_ID, AICF_IDchange] = useState("");

  const [whatsappNo, Whatsapp_nochange] = useState("");



  const [city, citychange] = useState("");
  const [StatesId, statechange] = useState("");

  const [batch_mode, preffered_batch_mode] = useState("");
  const [branchid, preffered_branchId] = useState("");
///------------------------------------------------------------------------------------------
const [photo, setStudentphoto] = useState("null");
const [birth_certificate_photo, birth_certificate_photochange] = useState("null");
const [aadharcard, aadharcardchange] = useState("null");
const navigate = useNavigate();



////-----------------------------------------------------------------------------------

const handlephotochange = (e) => { 
  setStudentphoto(e.target.files[0]);
}


const handlebirthphotochange = (e) => { 
  birth_certificate_photochange(e.target.files[0]);
}
const handleaadharphotochange = (e) => { 
  aadharcardchange(e.target.files[0]);
}




const postAPI = () => {

  const formData = new FormData();
  formData.append('Id',setId);
  formData.append('EnquiryId', enquiry_Id);
  formData.append('RegistrationNo', registration_no );
  formData.append('RegistrationDate', registationdate );

  formData.append('StudentName', student_name);
  formData.append('Reference', reference);
  formData.append('ParentsName', parent_name);
  formData.append('ParentsJob', parents_job);
  formData.append('Address', address);
  formData.append('MobileNo', mobile_no);

  formData.append('SchoolName', school_name);
  formData.append('SchoolTiming', school_timing);
  formData.append('EmailId', email_ID);
  formData.append('MCAId', MCA_ID);
  formData.append('FIDEId', FIDE_ID);
  formData.append('AICFId', AICF_ID);
  formData.append('CityId', city);
  formData.append('StateId', StatesId);


  formData.append('BranchId', branchid);
  formData.append('BatchId', batch_mode);
  
  formData.append('WhatsappNo', whatsappNo);

  formData.append('StudentPhoto', photo);
  formData.append('BirthCertificate', birth_certificate_photo);
  
  formData.append('AddharCard', aadharcard);
     

      
    
     
  try {
    const response =  axios.post("https://peapi.microtechsolutions.co.in/php/setregistration.php", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // Handle the response
    console.log(response.data);
    alert("Data Update Successfully !!!")
    navigate('/listregistration');

  } catch (error) {
    console.error('Error uploading image:', error);
  }
 
};
       

    //-----------------------------------------------------------------------------------------------------------------




  const {Id}=useParams();

  
useEffect(() => {
  const getcategory = async () => {
    const res = await fetch("https://peapi.microtechsolutions.co.in/php/getidregis.php?Id=" + Id);
    const getdata = await res.json();

 
    studentdata(getdata);

  };

  getcategory();

}, []);


const studentdata=(getdata)=>{
  {
    getdata.map((item)=>(
      changeId(item.Id),
      enquiry_Idchange(item.EnquiryId),
      registration_nochange(item.RegistrationNo),
      registationdatechange((item.RegistrationDate.date).substring(0, 10)),
    
    student_namechange (item.StudentName),
    referencechange(item.Reference),
    parent_namechange(item.ParentsName),
    parents_jobchange(item.ParentsJob),
    addresschange(item.Address),
    mobile_nochange(item.MobileNo),
    school_namechange(item.SchoolName),
    school_timingchange(item.SchoolTiming),
    email_IDchange(item.EmailId),
    MCA_IDchange(item.MCAId),
    FIDE_IDchange(item.FIDEId),
    AICF_IDchange(item.AICFId),
    Whatsapp_nochange(item.WhatsappNo),
    citychange(item.CityId),
    statechange(item.StateId),
    preffered_batch_mode(item.BatchId),
    preffered_branchId(item.BranchId)
    
    
  
  
  
    ))
  }
  
  }


  const [cities, setcities] = useState([]);



  const getCityData = async () => {
    const url = 'https://peapi.microtechsolutions.co.in/php/getcity.php';
    let result = await fetch(url);
    result = await result.json();
    setcities(result);
    console.log("data Fetch Successfully")
    console.log(cities)
  }




  const [State, setState] = useState([]);
  const getStateData = async () => {
    const url = 'https://peapi.microtechsolutions.co.in/php/getstate.php';
    let result = await fetch(url);
    result = await result.json();
    setState(result);
    console.log("data Fetch Successfully")
    console.log(State)
  }


  const [Batch, setBatch] = useState([]);
     const getBatcheData = async () => {
     const url = 'https://peapi.microtechsolutions.co.in/php/getbatchmst.php';
     let result = await fetch(url);
    result = await result.json();
    setBatch(result);
    console.log("data Fetch Successfully")
    console.log(State)
  }


  const [Branch, setBranch] = useState([]);
  const getBranchData = async () => {
    const url = 'https://peapi.microtechsolutions.co.in/php/getbranchmst.php';
    let result = await fetch(url);
    result = await result.json();
    setBranch(result);
    console.log("data Fetch Successfully")
    console.log(State)
  }

  useEffect(() => {
    setTimeout(() => {
      getBranchData();
    

    }, 1000);
  },[]); 

  useEffect(() => {
    setTimeout(() => {
      getBatcheData();
    

    }, 1000);
  },[]); 

  useEffect(() => {
    setTimeout(() => {
      getStateData();
    

    }, 1000);
  },[]); 

  useEffect(() => {
    setTimeout(() => {
      getCityData();
    

    }, 1000);
  },[]); 


 
  return (
    <div className="d-flex">
      <div>
        <Sidebar />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
          height: "100vh",
          overflowY: "hidden",
        }}
      >
        <Navbar />
        <div style={{ height: "100%" }}>
          <div
            style={{
              padding: "20px 5%",
              height: "calc(100% - 64px)",
              overflowY: "scroll",
              background: "whitesmoke",
            }}
          >
            <div
              style={{
                display: "grid",
                background: "white",
                gridTemplateColumns: "repeat(1, minmax(200px, 1200px))",
              }}
            >


              <div className="page-wrapper" >
                <div className="content container-fluid" >
                  <div className="page-header" style={{ backgroundColor: '#0b6fa1' }}>
                    <div className="row align-items-center" >
                      <div className="col-sm-12"  >
                        <div className="page-sub-header" >
                          <h3 className="page-title" style={{ color: "white", textAlign: 'center' }} >Update</h3>
                          <Link exact to="/listregistration" activeClassName="activeClicked">
                            <button style={{ background: "#D9468D", color: 'white', borderColor: 'CaptionText', borderRadius: '8px', boxSizing: "border-box" }} >List</button>
                          </Link>


                        </div>
                      </div>
                    </div>
            
                    <div className="row" >
                      <div className="col-sm-12" >
                        <div className="card comman-shadow" style={{ backgroundColor: '#55a6cf' }}>
                          <div className="card-body">
                            <form>
                              <div className="row">




                              

                              <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>
                                      ID <span className="login-danger">*</span>
                                    </label>
                                    <input 
                                    
                                      value={Id}
                                 disabled
                                    
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter  Enquiry No"
                                    />
                                  </div>
                                </div>


                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>
                                      Enquiry Id <span className="login-danger">*</span>
                                    </label>
                                    <input required
                                   value={enquiry_Id}
                                  
                                   onChange={e => enquiry_Idchange(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter  Enquiry No"
                                    />
                                  </div>
                                </div>




                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>
                                      Registration No <span className="login-danger">*</span>
                                    </label>
                                    <input
                                    value={registration_no}
                                      onChange={e => registration_nochange(e.target.value)}

                                      className="form-control"
                                      type="text"
                                      placeholder="Enter  Enquiry No"
                                    />
                                  </div>
                                </div>
                             
                               
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms calendar-icon">
                                    <label>
                                      Registration Date <span className="login-danger">*</span>
                                    </label>
                                    <input
                                      values={registationdate}
                                      onChange={e => registationdatechange(e.target.value)}
                                      className="form-control datetimepicker"
                                      type="date"
                                      placeholder="DD-MM-YYYY"
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>
                                      Student Name <span className="login-danger">*</span>
                                    </label>
                                    <input
                                    value={student_name}
                                      onChange={e => student_namechange(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter Student Name"
                                    />
                                  </div>
                                </div>


                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms calendar-icon">

                                    <label className="file-upload image-upbtn mb-0">
                                      Student Photo
                                 
                                       <input    type="file"
                                             id="image"
                                            accept="image/*"
                                             
                                           onChange={handlephotochange} />
                                     
                                    </label>
                                  </div>
                                </div>
                            
                              
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>Reference </label>
                                    <input
                                    value={reference}
                                      onChange={e => referencechange(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Reference"
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>Parents Name </label>
                                    <input
                                    value={parent_name}
                                      onChange={e => parent_namechange(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter Parents Name"
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>Parent's Job Profile </label>
                                    <input 
                                    value={parents_job}
                                      onChange={e => parents_jobchange(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter Job Profile"
                                    />
                                  </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                  <div class="form-group">
                                    <label>Address<span class="text-danger">*</span></label>
                                    <input value={address} onChange={e => addresschange(e.target.value)} type="text" class="form-control" />
                                  </div>
                                </div>





                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>City ID </label>


                                    <select className="form-select" aria-label="Default select example" value={city}   onChange={e => citychange(e.target.value)} >
                                    <option selected Value="">Choose City</option>
                                    {cities.map((cityid) => {
                                        return (
                                            <>
                                            <option key={cityid.Id} value={cityid.Id}>{cityid.Name} </option>
                                            </>
                                        )
                                    })}
                                </select>


                                  </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>State ID </label>
                                    <select className="form-select" aria-label="Default select example" value={StatesId}  onChange={e => statechange(e.target.value)} >
                                    <option selected Value="">Choose State</option>
                                    {State.map((state) => {
                                        return (
                                            <>
                                            <option key={state.Id} value={state.Id}>{state.State}</option>
                                            </>
                                        )
                                    })}
                                </select>



                                  </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>Mobile No </label>
                                    <input
                                    value={mobile_no}
                                      onChange={e => mobile_nochange(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter Mobile Number"
                                    />
                                  </div>


                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>Whatsapp No </label>
                                    <input
                                    value={whatsappNo}
                                      onChange={e => Whatsapp_nochange(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter Mobile Number"
                                    />
                                  </div>


                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>School name </label>
                                    <input 
                                    value={school_name}
                                      onChange={e => school_namechange(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter school name"
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>School Timing </label>
                                    <input
                                    value={school_timing}
                                      onChange={e => school_timingchange(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter Timing"
                                    />
                                  </div>
                                </div>



                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms calendar-icon">

                                    <label className="file-upload image-upbtn mb-0">
                                      Birth Certificate Photo <input  onChange={handlebirthphotochange} type="file" />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms calendar-icon">

                                    <label className="file-upload image-upbtn mb-0">
                                      Aadhar Card Photo <input    onChange={handleaadharphotochange} type="file" />
                                    </label>
                                  </div>
                                </div>
                            
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>
                                      E-Mail Id <span className="login-danger">*</span>
                                    </label>
                                    <input
                                    value={email_ID}
                                      onChange={e => email_IDchange(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter Email Address"
                                    />
                                  </div>
                                </div>



                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>
                                      Batch ID <span className="login-danger">*</span>
                                    </label>
                                    <select className="form-select" aria-label="Default select example" value={batch_mode} onChange={e => preffered_batch_mode(e.target.value)}>
                                    <option selected Value="">Choose Batch</option>
                                    {Batch.map((batch) => {
                                        return (
                                            <>
                                            <option key={batch.Id} value={batch.Id}>{batch.BatchName}</option>
                                            </>
                                        )
                                    })}
                                </select>
                                  </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>
                                      Branch Id <span className="login-danger">*</span>
                                    </label>
                                    <select className="form-select" aria-label="Default select example"  value={branchid} onChange={e => preffered_branchId(e.target.value)} >
                                    <option selected Value="">Choose Branch</option>
                                    {Branch.map((branch) => {
                                        return (
                                            <>
                                            <option key={branch.Id} value={branch.Id}>{branch.BranchName}</option>
                                            </>
                                        )
                                    })}
                                </select>
                                  </div>
                                </div>
                              

                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>MCA ID </label>
                                    <input
                                    value={MCA_ID}
                                      onChange={e => MCA_IDchange(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter MCA ID"
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>FIDE ID </label>
                                    <input
                                    value={FIDE_ID}
                                      onChange={e => FIDE_IDchange(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter FIDE ID"
                                    />
                                  </div>
                                </div>


                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>AICF ID </label>
                                    <input
                                    value={AICF_ID}
                                      onChange={e => AICF_IDchange(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter AICF ID"
                                    />
                                  </div>
                                </div>


                                <div className="col-12">
                                  <div className="student-submit">
                                    <button onClick={postAPI} className="btn btn-primary">
                                      Submit
                                    </button>






                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>


              <footer className="mx-auto my-3 text-center">
                <small>&copy; Microtech, 2023. All rights reserved.</small>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>



  );
};

