
import React, { useState,useEffect } from "react";

import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";

import {Link ,useParams } from "react-router-dom";






const StudentView = () => {

  const {Id}=useParams();
const [studentdetails ,setStudentDetails]=useState();


useEffect(() => {
  const getcategory = async () => {
    const res = await fetch("https://peapi.microtechsolutions.co.in/php/getidregis.php?Id=" + Id);
    const getdata = await res.json();

 
    setStudentDetails(getdata);

  };

  getcategory();

}, [Id]);









  return (
    <div className="d-flex">
      <div>
        <Sidebar />
      </div>
      <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
        <Navbar />
        <div style={{ height: "100%" }}>
          <div style={{ padding: "20px 5%", height: "calc(100% - 80px)", overflowY: "scroll" }}>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(1, minmax(200px, 1920))" }}>

           
              <Link exact to="/listregistration" activeClassName="activeClicked">
                <button style={{ marginLeft: '10px' }} className="btn btn-info" >Back</button>
              </Link>

                          
                            
                              
          



                <div class="page-wrapper">
<div  class="content container-fluid">
<div class="page-header">
<div class="row">
<div class="col-sm-12">
<div class="page-sub-header">
<h3 class="page-title" style={{justifyContent:"center",textAlign:'center',color:'ActiveBorder'}}> Student view</h3>
<ul class="breadcrumb">

<li class="breadcrumb-item active" style={{color:'blue'}}>Student Id: {Id}</li>
</ul>
</div>
</div>
</div>
</div>
<div class="card">
<div class="card-body">
<div class="row">
<div class="col-md-12">



<div class="student-profile-head">
<div class="profile-bg-img">

<div class="row">
<div class="col-lg-12 col-md-12">
<div class="profile-user-box">
<div class="profile-user-img">


</div>
</div>


{studentdetails ?
       studentdetails.map((items)=>(
     
     

<div class="names-profiles">

<h4>Registration No:{items.RegistrationNo} </h4>
<h5>Registration Date:{(items.RegistrationDate.date).substring(0, 10)}</h5>
<h5>Enquiry Id:{items.EnquiryId}</h5>
<br></br>

<h4 style={{color:'red'}}>Profile :</h4>
<h5 style={{color:'Blue'}}>Student Image</h5>
<img  style={{height:"10rem",width:"10rem"}}  src={items.StudentPhoto} alt="Upload Picture"  />


<h2 style={{justifyContent:"center",textAlign:'left',color:'yellowgreen'}}>Student Information</h2>

<h5>Student Name:   {items.StudentName}</h5>
<h5>Parent's name:   {items.ParentsName}</h5>
<h5>Parent's Job:   {items.ParentsJob}</h5>
<h5>Address:   {items.Address}</h5> 
<h5> School Name: {items.SchoolName}</h5>
<h5> School Timing: {items.SchoolTiming}</h5>
<h5>Email-Id :  {items.EmailId}</h5> 
<h5>Reference :  {items.Reference}</h5> 



<br></br>
<h2 style={{justifyContent:"center",textAlign:'left',color:'yellowgreen'}}>ID</h2>
<h5>MCA ID: {items.MCAId}</h5>
<h5>FIDE ID: {items.FIDEId}</h5>
<h5>AICF ID: {items.AICFId}</h5>

<br></br>

<h2 style={{justifyContent:"center",textAlign:'left',color:'yellowgreen'}}>Contact Details:</h2>
<h5>Mobile No: {items.MobileNo}</h5>
<h5>Whatsapp No: {items.WhatsappNo}</h5>

<br></br>

<h2 style={{justifyContent:"center",textAlign:'left',color:'yellowgreen'}}>Documents:</h2>

<h5>BirthCertificate Photo :</h5>
<img  style={{height:"10rem",width:"10rem"}}  src={items.BirthCertificate} alt="Upload Picture"  />

  <br></br>
<h5>AddharCard Photo : </h5>
<img  style={{height:"10rem",width:"10rem"}}  src={items.AddharCard} alt="Upload Picture"  />

</div>

)):null
}





































</div></div></div></div></div></div></div></div></div></div></div>
    
     
            <footer className="mx-auto my-3 text-center">
              <small>&copy; Microtech, 2023. All rights reserved.</small>
            </footer>
            
          </div>

        </div>
      </div>
  </div>
  );
};


export default StudentView;





