
import axios from "axios";
import { Link,useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";







export const UpdateFees = () => {


  const[id ,changeId]=useState("");
  const [studentid, setStudentId] = useState("");
  const [feesdate, setFeesDate] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState('');
  const [fees, setFees] = useState("");





  const navigate = useNavigate();


 
     
        // 'Id' : id,
        // 'StudentId': studentid,
        // 'FeesDate': feesdate,

        // "Month": month,
        // "Year": year,
        // "Fees": fees,


        
  const postAPI = () => {

    const formData = new FormData();
    formData.append('Id', id);

  
    formData.append('Month', month);
    formData.append('Year', year);
    formData.append('Fees', fees);

  

    try {
      const response = axios.post('https://peapi.microtechsolutions.co.in/php/setfees.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

       console.log(response.data);
       alert("Data Successfully Update")

      navigate('/listFees');
 
    } catch (error) {
      console.error('Error uploading image:', error);
    }

  };

   
  const {Id}=useParams();


 
useEffect(() => {
  const getcategory = async () => {
    const res = await fetch("https://peapi.microtechsolutions.co.in/php/getidfees.php?Id=" + Id);
    const getdata = await res.json();

 
    studentdata(getdata);

  };

  getcategory();

}, []);


const studentdata=(getdata)=>{
  {
    getdata.map((item)=>(
      changeId(item.Id),
      setStudentId(item.StudentId),
      setFeesDate ((item.FeesDate.date).substring(0, 10)),
      setMonth(item.Month),
    
    setYear (item.Year),
    setFees (item.Fees)

   
    
  
  
  
    ))
  }
  
  }










  return (
    <div className="d-flex">
      <div>
        <Sidebar />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
          height: "100vh",
          overflowY: "hidden",
        }}
      >
        <Navbar />
        <div style={{ height: "100%" }}>
          <div
            style={{
              padding: "20px 5%",
              height: "calc(100% - 64px)",
              overflowY: "scroll",
              background: "whitesmoke",
            }}
          >
            <div
              style={{
                display: "grid",
                background: "white",
                gridTemplateColumns: "repeat(1, minmax(200px, 1200px))",
              }}
            >


              <div className="page-wrapper" >
                <div className="content container-fluid" >
                  <div className="page-header" style={{ backgroundColor: '#0b6fa1' }}>
                    <div className="row align-items-center" >
                      <div className="col-sm-12"  >
                        <div className="page-sub-header" >
                          <h3 className="page-title" style={{ color: "white", textAlign: 'center' }} >Fees</h3>
                          <Link exact to="/listFees" activeClassName="activeClicked">
                            <button style={{ background: "#D9468D", color: 'white', borderColor: 'CaptionText', borderRadius: '8px', boxSizing: "border-box" }} >Back</button>
                          </Link>


                        </div>
                      </div>
                    </div>
                    <div className="row" >
                      <div className="col-sm-12" >
                        <div className="card comman-shadow" style={{ backgroundColor: '#55a6cf' }}>
                          <div className="card-body">
                            <form>
                              <div className="row">


                              <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>
                                      ID <span className="login-danger">*</span>
                                    </label>
                                    <input 
                                    
                                      value={Id}
                                         disabled
                                    
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter  Enquiry No"
                                    />
                                  </div>
                                </div>


                        


                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>
                                      Student Id <span className="login-danger">*</span>
                                    </label>

                                    <input
                                              
                                        value={studentid}
                                        onChange={e => setStudentId(e.target.value)}
                                      className="form-control"
                                     

                                    />
                                  </div>
                                </div>








                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>Date </label>
                                    <input
                                         value={feesdate}
                                    
                                      className="form-control"
                                        disabled    
                                     
                                    />
                                  </div>
                                </div>

                              
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>Month </label>
                                    <select        value={month}   className="form-control select"  onChange={e => setMonth(e.target.value)} >

                                      <option>Please Select Month </option>
                                      <option value='1'  > January </option>
                                      <option value='2'>February</option>
                                      <option value='3'>March</option>
                                      <option value='4'>April</option>
                                      <option value='5'>May</option>
                                      <option value='6'>June</option>
                                      <option value='7'>July</option>
                                      <option value='8'>August</option>
                                      <option value='9'>September</option>
                                      <option value='10'>October</option>
                                      <option value='11'>November</option>
                                      <option value='12'>December</option>
                                 




                                    </select>



                                  </div>
                                </div>


                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>Year </label>
                                    <input
                                         value={year}
                                      onChange={e => setYear(e.target.value)}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter Year"
                                    />
                                  </div>
                                </div>



                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>Fees </label>
                                    <input
                                      value={fees}
                                      onChange={e => setFees(e.target.value)}
                                      className="form-control"
                                      type="Number"
                                      placeholder="Enter Fees"
                                    />
                                  </div>
                                </div>



                                <div className="col-12">
                                  <div className="student-submit">
                                    <button style={{ marginTop: "10PX" }} onClick={postAPI} className="btn btn-primary">
                                      Submit
                                    </button>






                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>


              <footer className="mx-auto my-3 text-center">
                <small>&copy; Microtech, 2023. All rights reserved.</small>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>



  );
};

export default UpdateFees;
