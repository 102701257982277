
import React, { useState,useEffect } from "react";

import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";

import {Link ,useParams } from "react-router-dom";















                          
                            
                              
          











     




// // <br></br>

// // <h4 style={{color:'red'}}>Profile :</h4>
// // <h5 style={{color:'Blue'}}>Student Image</h5>



// // <h2 style={{justifyContent:"center",textAlign:'left',color:'yellowgreen'}}>Student Information</h2>




// // <h5> School Name: {items.SchoolName}</h5>
// // <h5> School Timing: {items.SchoolTiming}</h5>
// // <h5>Email-Id :  {items.EmailId}</h5> 




// // <br></br>


// // <br></br>

// // <h2 style={{justifyContent:"center",textAlign:'left',color:'yellowgreen'}}>Contact Details:</h2>
// // <h5>Mobile No: {items.MobileNo}</h5>
// // <h5>Whatsapp No: {items.WhatsappNo}</h5>

// // <br></br>

// // <h2 style={{justifyContent:"center",textAlign:'left',color:'yellowgreen'}}>Documents:</h2>

// // <h5>BirthCertificate Photo :</h5>
// // <img  style={{height:"10rem",width:"10rem"}}  src={items.BirthCertificate} alt="Upload Picture"  />

// //   <br></br>
// // <h5>AddharCard Photo : </h5>
// // <img  style={{height:"10rem",width:"10rem"}}  src={items.AddharCard} alt="Upload Picture"  />

// // </div>

// // )):null
// // }

















































 







           










const EnquiryView = () => {


  const {Id}=useParams();
  const [studentdetails ,setStudentDetails]=useState();
  
  
  useEffect(() => {
    const getcategory = async () => {
      const res = await fetch("https://peapi.microtechsolutions.co.in/php/getidenquiry.php?Id=" + Id);
      const getdata = await res.json();
  
   
      setStudentDetails(getdata);
  
    };
  
    getcategory();
  
  }, [Id]);
  
  
  console.log(studentdetails);
  const styles = {
    '.resume-container': {
      maxWidth: '1100px',
      margin: '0 auto',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      backgroundColor: '#f7f7f7',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    '.header': {
      textAlign: 'center',
      marginBottom: '20px',
    },
    'h1': {
      fontSize: '32px',
      color: '#333',
      marginBottom: '10px',
    },
    'h2': {
      fontSize: '26px',
      color: '#555',
      marginBottom: '10px',
    },
    'p': {
      fontSize: '18px',
      color: '#777',
      marginBottom: '15px',
    },
    '.photo-container': {
      textAlign: 'center',
      marginBottom: '20px',
    },
    '.photo': {
      borderRadius: '50%',
      maxWidth: '100%',
      height: 'auto',
    },
    '.documents-container': {
      display: 'flex',
      flexDirection: 'column',
    },
    '.document': {
      textAlign: 'center',
      padding: '20px',
      margin: '20px 0',
      border: '2px solid #e74c3c',
      borderRadius: '10px',
      backgroundColor: '#ecf0f1',
    },
  };

  return (
    <div className="d-flex">
    <div>
      <Sidebar />
    </div>
    <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
      <Navbar />
      <div style={{ height: "100%" }}>
        <div style={{ padding: "20px 5%", height: "calc(100% - 80px)", overflowY: "scroll" }}>
          <div style={{ display: "grid", gridTemplateColumns: "repeat(1, minmax(200px, 1920))" }}>

         
            <Link exact to="/listEnquiry" activeClassName="activeClicked">
              <button style={{ marginLeft: '10px' }} className="btn btn-info" >Back</button>
            </Link>


            <div class="page-wrapper">
<div  class="content container-fluid">
<div class="page-header">
<div class="row">
<div class="col-sm-12">
<div class="page-sub-header">
<h3 class="page-title" style={{justifyContent:"center",textAlign:'center',color:'ActiveBorder'}}> Student view</h3>
<ul class="breadcrumb">

<li class="breadcrumb-item active" style={{color:'blue'}}>Student Id: {Id}</li>
</ul>
</div>
</div>
</div>
</div>

{studentdetails?
       studentdetails.map((items) => ( 
    <div style={styles['.resume-container']}>

      <div style={styles['.header']}>
      
        <h1>{items.StudentName}</h1>
     
        <h2>{items.EmailId}</h2>
      </div>
 

      {/* Photo */}
      <div style={styles['.photo-container']}>
      <img  style={{height:"10rem",width:"10rem"}}  src={items.StudentPhoto} alt="Upload Picture"  />
      </div>

      {/* Resume Content */}
      <div>
      <h2 style={{justifyContent:"center",textAlign:'left',color:'yellowgreen'}}>Enquiry Details:-</h2>
          <h5>Enquiry Id:{items.Id}</h5>
          <h5>Enquiry  No:{items.EnquiryNo} </h5>
          <h5>Enquiry  Date:{(items.EnquiryDate.date).substring(0, 10)}</h5>


          <h2 style={{justifyContent:"center",textAlign:'left',color:'yellowgreen'}}>Contact Details:-</h2>
        <h5>Mobile No:   {items.MobileNo}</h5>
         <h5>Whatsapp No:   {items.WhatsappNo}</h5>


          <h2 style={{justifyContent:"center",textAlign:'left',color:'yellowgreen'}}>Parent Details:-</h2>
        <h5>Parent's name:   {items.ParentsName}</h5>
         <h5>Parent's Job:   {items.ParentsJob}</h5>
         <h5>Adress  :   {items.Address}</h5>



         <h2 style={{justifyContent:"center",textAlign:'left',color:'yellowgreen'}}>School Details:-</h2>
        <h5>School name:   {items.SchoolName}</h5>
         <h5>School Timing :   {items.SchoolTiming}</h5>
      

         <h2 style={{justifyContent:"center",textAlign:'left',color:'yellowgreen'}}>Reference</h2>
        <h5>Reference :  {items.Reference}</h5> 

        <h2 style={{justifyContent:"center",textAlign:'left',color:'yellowgreen'}}>ID</h2>
         <h5>MCA ID: {items.MCAId}</h5>
            <h5>FIDE ID: {items.FIDEId}</h5>
           <h5>AICF ID: {items.AICFId}</h5>

        {/* Documents Section */}
        <h2 style={{justifyContent:"center",textAlign:'left',color:'yellowgreen'}}>Documents</h2>
        <div style={styles['.documents-container']}>
          <div style={styles['.document']}>
            <p>BirthCertificate Photo</p>
            <img src={items.BirthCertificate} alt="Resume" style={{ maxWidth: '50%', height: 'auto' }} />
          </div>
          <div style={styles['.document']}>
            <p>Adharcard</p>
            <img src={items.AddharCard}  alt="Portfolio" style={{ maxWidth: '50%', height: 'auto' }} />
          </div>
          {/* Add more documents as needed */}
        </div>
     
      </div>
  
    </div>
       )):null}
            
     <footer className="mx-auto my-3 text-center">
              <small>&copy; Microtech, 2023. All rights reserved.</small>
            </footer>

    </div></div></div></div></div>
    </div></div>
  );
};

export default EnquiryView;
