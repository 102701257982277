import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import './Login.css';
import background from '../images/backchess.jpg'


const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const bgStyle = {
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
   
        height:"100vh",

    overflow: 'auto'
  }

  const handleLogin = async () => {
    try {
      const formData = new URLSearchParams();
      formData.append('Name', username);
      formData.append('Password', password);

      const response = await fetch('https://peapi.microtechsolutions.co.in/php/postlogin.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData.toString(),
      });

      if (response.ok) {
        // Authentication successful, navigate to the home page
        console.log('Login successful');
        alert("Welcome ANAYA'S CHESS CLUB")
        navigate('/home');
      } else {
        // Authentication failed, handle the error
        setError('Invalid username or password');
        alert("Incoreect PassWord ")
      }
    } catch (error) {
      console.error('Error during login:', error);
      setError('An unexpected error occurred');
    }
  };

  return (
    <div style={bgStyle}>
    <Container >
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <div className="login-container">
          <img    alt="panelImage" src="img/LOGO/logochess.jpg" style={{width:"500px",height:"150px",borderRadius:'25px'}}/>
           
            {error && <p style={{ color: 'red',textSize:'25px'}}>{error}</p>}
            <Form onSubmit={handleLogin}>
              <Form.Group controlId="formBasicUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control
                 
                  placeholder="Enter your username"
                  type="text" value={username} onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label >Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your password"
                
                 value={password} onChange={(e) => setPassword(e.target.value)} 
                  required
                />
              </Form.Group>

              <Button variant="primary" type="button" onClick={handleLogin}>
                Login
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
    </div>
  );
};

export default Login;






// <div>
//       <h2>Login</h2>
//       {error && <p style={{ color: 'red' }}>{error}</p>}
//       <form>
//         <label>
//           Username:
//           <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
//         </label>
//         <br />
//         <label>
//           Password:
//           <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
//         </label>
//         <br />
//         <button type="button" onClick={handleLogin}>
//           Login
//         </button>
//       </form>
//     </div>