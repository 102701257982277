

import React from "react";

import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";

import { useState } from "react";
import axios from "axios";


export const Coach = () => {


// Updated 









  // const updatedcoach = (txt) => {

  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
  //   var urlencoded = new URLSearchParams();
  //   urlencoded.append("Id",txt);
    
  //   var requestOptions = {
  //     method: 'POST',
  //     headers: myHeaders,
  //     body: urlencoded,
  //     redirect: 'follow'
  //   };
    
  //   fetch("https://peapi.microtechsolutions.co.in/php/setcoachmst.php", requestOptions)
  //     .then(response => response.text())
  //     .then(result => console.log(result))
  //     .catch(error => console.log('error', error));
  // }





//---------------------------------------------------------------------

  const [CoachName, CoachNamechange] = useState("");
  const [MobileNo, MobileNochange] = useState("");



  const postAPI = () => {

    let config = {
      method: 'post',
      mode: "cors",
      maxBodyLength: Infinity,
      url: "https://peapi.microtechsolutions.co.in/php/postcoachmst.php",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: {

        'CoachName': CoachName,
        'MobileNo': MobileNo,


      }
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);

      });

  }


  return (
    <div className="d-flex">
      <div>
        <Sidebar />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
          height: "100vh",
          overflowY: "hidden",
        }}
      >
        <Navbar />
        <div style={{ height: "100%" }}>
          <div
            style={{
              padding: "20px 5%",
              height: "calc(100% - 64px)",
              overflowY: "scroll",
              background: "whitesmoke",
            }}
          >
            <div
              style={{
                display: "grid",
                background: "white",
                gridTemplateColumns: "repeat(1, minmax(200px, 1200px))",
              }}
            >
              <>


                <div class="page-wrapper">
                  <div class="content container-fluid">

                    <div className="page-header" style={{ backgroundColor: '#0b6fa1' }}>
                      <div class="row align-items-center">
                        <div class="col">
                          <h3 class="page-title" style={{ color: "white", textAlign: 'center' }}>Coach Master</h3>

                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-12">
                        <div class="card">
                          <div class="card-body" style={{ backgroundColor: '#55a6cf' }}>
                            <form>
                              <div class="row">
                                <div class="col-12 col-sm-4">
                                  <div class="form-group local-forms">
                                    <label>Coach ID <span class="login-danger">*</span></label>
                                    <input disabled type="text" class="form-control" />
                                  </div>
                                </div>
                                <div class="col-12 col-sm-4">
                                  <div class="form-group local-forms">
                                    <label>Coach Name <span class="login-danger">*</span></label>
                                    <input required onChange={e => CoachNamechange(e.target.value)} type="text" class="form-control" />
                                  </div>
                                </div>
                                <div class="col-12 col-sm-4">
                                  <div class="form-group local-forms">
                                    <label>Mobile <span class="login-danger">*</span></label>
                                    <input required onChange={e => MobileNochange(e.target.value)} type="text" class="form-control" />
                                  </div>
                                </div>

                                <div class="col-12">
                                  <div class="student-submit">
                                    <button onClick={postAPI} class="btn btn-primary">Submit</button>
                                  </div>
                                </div>



                                
                                {/* <div class="col-12">
                                  <div class="student-submit">
                                    <button onClick={postAPI2} class="btn btn-primary">Submit2</button>
                                  </div>
                                </div> */}
                                               
                                        
                               

                          <label>Update</label>
                                <div class="col-12 col-sm-4">
                                  <div class="form-group local-forms">
                                    <label>Coach Name <span class="login-danger">*</span></label>
                                    <input required onChange={e => CoachNamechange(e.target.value)} type="text" class="form-control" />
                                  </div>
                                </div>
                                <div class="col-12 col-sm-4">
                                  <div class="form-group local-forms">
                                    <label>Mobile <span class="login-danger">*</span></label>
                                    <input required onChange={e => MobileNochange(e.target.value)} type="text" class="form-control" />
                                  </div>
                                </div>

                                <div class="col-12">
                                  <div class="student-submit">
                                    <button onClick={postAPI} class="btn btn-primary">Submit</button>
                                  </div>
                                </div>



                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>






              </>



            </div>
            <footer className="mx-auto my-3 text-center">
              <small>&copy; Microtech, 2023. All rights reserved.</small>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};














