import React from "react";
import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";
import { useState, useEffect } from "react";
import { CDBTable, CDBTableHeader, CDBTableBody, CDBBtn } from "cdbreact";
import { toast } from "react-toastify";
import axios from "axios";
import { Link } from "react-router-dom";



export const Attendence = () => {



  const [branchid, setBranchId] = useState();
  const [batchid, setBatchId] = useState();



  const [studentsData, setStudentsData] = useState([]);
  const [attendanceData, setAttendanceData] = useState({});
  const [currentDate, setCurrentDate] = useState('');
  const [selectAll, setSelectAll] = useState(false);


  const [show, setShow] = useState();

  const [attendancedate, setAttendancedate] = useState("");

 

  const fetchDate = async () => {

    const response = await axios.get('https://peapi.microtechsolutions.co.in/php/getattendate.php?AttenDate=' + attendancedate)// Replace with your API endpoint

      .then((response) => {

        checkdate(response.data);
      })

      .catch((error) => {
        console.log(error);
      });
  }



  const checkdate = (txt) => {
    let data = txt
    let count = Object(data).length
    if (count > 1) {
      setShow(false);
    } else {
      setShow(true);
    }

  }





  const fetchStudentData = async () => {
    try {
      const response = await axios.get('https://peapi.microtechsolutions.co.in/php/getattenbybrnbat.php?BatchId=' + batchid + '&BranchId=' + branchid); // Replace with your API endpoint
      setStudentsData(response.data);

      // Initialize attendance data with default values
      const initialAttendanceData = response.data.reduce((acc, student) => {
        acc[student.id] = { present: 0 }; // Default value set to 0
        return acc;
      }, {});
      setAttendanceData(initialAttendanceData);

      const currentDateFormatted = new Date().toISOString().split('T')[0];
      setCurrentDate(currentDateFormatted);
      alert(' Student List ');
    } catch (error) {
      console.error('Error fetching student data', error);
      alert('Select Branch Name ANd Batch Name ');
    }
  };








  const [Batch, setBatch] = useState([]);


  const getBatcheData = () => {
    axios.get('https://peapi.microtechsolutions.co.in/php/getbatchmst.php').then((response) => {
      const result = response.data;
      setBatch(result);
    }).catch((error) => {
      toast.error('Error while fetching product details');
    });
  }


  useEffect(() => {

    getBatcheData();



  }, []);




  const [Branch, setBranch] = useState([]);


  const getBranchData = () => {
    axios.get('https://peapi.microtechsolutions.co.in/php/getbranchmst.php').then((response) => {
      const result = response.data;
      setBranch(result);
    }).catch((error) => {
      toast.error('Error while fetching product details');
    });
  }


  useEffect(() => {

    getBranchData();



  }, []);







  const handleCheckboxChange = (studentId) => {
    setAttendanceData((prevData) => {
      const existingData = prevData[studentId] || { present: 0 };

      const updatedData = {
        ...prevData,
        [studentId]: {
          ...existingData,
          present: existingData.present === 1 ? 0 : 1, // Toggle between 1 and 0
        },
      };

      // Log the entire attendance data to the console whenever a checkbox is changed
      console.log('Attendance data:', updatedData);

      return updatedData;
    });
  };

  const handleSelectAllChange = () => {
    setSelectAll((prevSelectAll) => !prevSelectAll);

    // Update the attendance data based on the "Select All" checkbox state
    setAttendanceData((prevData) => {
      const updatedData = {};

      Object.keys(prevData).forEach((studentId) => {
        updatedData[studentId] = {
          present: selectAll ? 0 : 1, // Invert the selection state when "Select All" is clicked
        };
      });

      // Log the entire attendance data to the console whenever "Select All" is clicked
      console.log('Attendance data:', updatedData);

      return updatedData;
    });
  };




  const handleSubmit = () => {

    const isAnyCheckboxSelected = Object.values(attendanceData).some(
      (studentData) => studentData.present === 1
    );

    
  
    // Extract student ids with their attendance status and today's date
    const attendanceArray = Object.keys(attendanceData).map((studentId) => ({
      AttenDate: currentDate,
      BranchId: branchid,

      BatchId: batchid,
      StId: parseInt(studentId),

      Present: isAnyCheckboxSelected ? attendanceData[studentId].present : 0,


    }));


    const attendanceJson = JSON.stringify(attendanceArray, null, 2);


    // Log all attendance data to the console
    //console.log( attendanceJson);


    const qs = require('qs');
    let data = qs.stringify({
      'Prs': attendanceJson
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://peapi.microtechsolutions.co.in/php/posttest.php',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        window.location.reload();

      alert("Data Send Successfully !!!")
      })
      .catch((error) => {
        console.log(error);
      });


  };
  return (


    <div className="d-flex">
                     <div>
                        <Sidebar />
                              </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
          height: "100vh",
          overflowY: "hidden",
        }}
      >
        <Navbar />
        <div style={{ height: "100%" }}>
          <div
            style={{
              padding: "20px 5%",
              height: "calc(100% - 64px)",
              overflowY: "scroll",
              background: "whitesmoke",
            }}
          >
            <div
              style={{
                display: "grid",
                background: "white",
                gridTemplateColumns: "repeat(1, minmax(200px, 1200px))",
              }}
            >
              


                <div class="page-wrapper">
                  <div class="content container-fluid">

                    <div className="page-header" style={{ backgroundColor: '#0b6fa1' }}>
                      <div class="row align-items-center">
                           <div class="col">
                           <h3 class="page-title" style={{ color: "white", textAlign: 'center' }}>Attendence</h3>

                           <Link exact to="/listAttendance" activeClassName="activeClicked">
                            <button style={{ background: "#D9468D", color: 'white', borderColor: 'CaptionText', borderRadius: '8px', boxSizing: "border-box" }} >List</button>
                          </Link>

                             </div>

                      </div>
                    </div>



                    <div class="row">
                      <div class="col-sm-12">
                        <div class="card">
                          <div class="card-body" style={{ backgroundColor: '#55a6cf' }}>
                         
                              <div class="row">


                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms calendar-icon">
                                    <label>
                                      Date <span className="login-danger">*</span>
                                    </label>
                                    <input
                                      onChange={e => setAttendancedate(e.target.value)}

                                      className="form-control datetimepicker"
                                      type="date"
                                      placeholder="DD-MM-YYYY"
                                    />
                                  </div>
                                </div>

                                <div class="col-12" style={{ paddingTop: "10px" }}>
                                  <div class="student-submit">
                                    <button class="btn btn-success" onClick={fetchDate} >Submit</button>
                                  </div>
                                </div>



                                {(() => {

                                  if (show === true) {
                                    return (
                                    <div>
                                    <form>
                                    <div>
 
                                      <div className="col-12 col-sm-4" >
                                     <div className="form-group local-forms">
                                    <label>
                                      Branch Name <span className="login-danger">*</span>
                                    </label>
                                    <select className="form-select" aria-label="Default select example" value={branchid} onChange={e => setBranchId(e.target.value)} >
                                      <option selected Value="">Choose Branch</option>
                                      {Branch.map((branch) => {
                                        return (
                                          <>
                                            <option key={branch.Id} value={branch.Id}>{branch.BranchName}</option>
                                          </>
                                        )
                                      })}
                                    </select>

                                  </div>
                                </div>

                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>
                                      Batch Name <span className="login-danger">*</span>
                                    </label>
                                    <select className="form-select" aria-label="Default select example" value={batchid} onChange={e => setBatchId(e.target.value)}>
                                      <option selected Value="">Choose Batch</option>
                                      {Batch.map((batch) => {
                                        return (
                                          <>
                                            <option key={batch.Id} value={batch.Id}>{batch.BatchName}</option>
                                          </>
                                        )
                                      })}
                                    </select>

                                  </div>
                                </div>


                                <div class="col-12" style={{ paddingTop: "10px", paddingBottom: "25px" }} >
                                  <div class="student-submit">
                                    <button class="btn btn-primary" onClick={fetchStudentData}>Submit</button>
                                  </div>
                                </div>
                                </div>
                            </form>


                            <CDBBtn responsive type="Date" style={{color:"black",fontSize:"20px",fontSmooth:'xx-large',backgroundColor:"ButtonHighlight"}} > {attendancedate}</CDBBtn>

                            <CDBTable responsive>
                              <CDBTableHeader  color="Dark" >


                                <tr>

                                  <th>Student ID</th>
                                  <th>Student Name</th>
                                  <th>Present_All_
                                    <input
                                      type="checkbox"
                                      checked={selectAll}
                                      onChange={handleSelectAllChange}
                                    />

                                  </th>



                                </tr>
                              </CDBTableHeader>

                              <CDBTableBody>


                                {studentsData.map((student) => (
                                  <tr key={student.Id}>


                                    <td>{student.Id}</td>
                                    <td>{student.StudentName}</td>

                                    <td>

                                      <input
                                        type="checkbox"
                                        checked={attendanceData[student.Id]?.present === 1}
                                        onChange={() => handleCheckboxChange(student.Id)}
                                      />
                                    </td>

                                  </tr>

                                ))}


                              </CDBTableBody>
                            </CDBTable>

                            <button class="btn btn-success" onClick={handleSubmit}>Send Data</button>
                      
                               </div>
                         

                                      
                                    )
                                  }

                                  else {
                                    return (
                                      <h1>Data is Already Submitted !!!</h1>
                                    );
                                  }
                                })()}




                               


                               

                        


                        


                        
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>






             


 </div>
            <footer className="mx-auto my-3 text-center">
              <small>&copy; Microtech, 2023. All rights reserved.</small>
            </footer>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
