import React from "react";


import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import "./Home.css";


import background from '../images/backchess.jpg'

import '../pages/backgr.css';


export const Home = () => {
  const bgStyle = {
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    height: '100vh',
    overflow: 'auto'
  }

 
  return ( 
    <div className="dashboard d-flex" >
    	<div>
      	<Sidebar/>
      </div>
      <div style={{flex:"1 1 auto", display:"flex", flexFlow:"column", height:"100vh", overflowY:"hidden"}}>
        <Navbar/>
           
        <div style={bgStyle}>

        {/* <h2  style={{fontSize:"75px",alignItems:'center',display:"flex",justifyContent:'center',padding:'150px',color:'gold',font:"caption"}}>Wel-come Chess Club</h2> */}

        </div>



      </div>
      </div>
   
  );
}


export default Home;