
import React, { useState, useEffect } from "react";
import { CDBTable, CDBTableHeader, CDBTableBody } from "cdbreact";
import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";
import {  Pagination, Form, Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'



import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { TfiPencilAlt } from "react-icons/tfi";

const EnquiryList = () => {
  
const navigate=useNavigate();




//view enquiry

const ViewEnquirys=(Id)=>{

  navigate('/enquiryView/'+Id)

}


  // update Enquiry 

const UpdateEnquirys=(Id)=>{

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  
  var urlencoded = new URLSearchParams();
  urlencoded.append("Id",Id);
  
  var requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow'
  };
  
  fetch("https://peapi.microtechsolutions.co.in/php/setenquiry.php", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result.txt))
    .catch(error => console.log('error', error));
  navigate('/enquiryUpdate/'+Id)

}





  //  Delete //


  const delEnquirys = (txt) => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    var urlencoded = new URLSearchParams();
    urlencoded.append("Id",txt);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch("https://peapi.microtechsolutions.co.in/php/delenquiry.php", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
     alert("Deleted Successfully")
     
     window.location.reload(); //reload page

  }




 ///-------------------------------------------

//  List Of The Data
 
    const [category, setCategory] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);


    useEffect(() => {
      const getcategory = async () => {
        const res = await fetch("https://peapi.microtechsolutions.co.in/php/getenquiry.php");
        const getdata = await res.json();
        setCategory(getdata);
        console.log(getdata);
  
      };
  
      getcategory();
  
    }, []);

   


//pagignation 

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems  = category.slice(indexOfFirstItem, indexOfLastItem);
   

  
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

   
 
    const handleItemsPerPageChange = (e) => {
      const newItemsPerPage = parseInt(e.target.value, 10);
      if (!isNaN(newItemsPerPage) && newItemsPerPage > 0) {
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1); // Reset to the first page when changing items per page
      }
    };


 


  return (
    <div className="d-flex">
      <div>
        <Sidebar />
      </div>
      <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
        <Navbar />
        <div style={{ height: "100%" }}>
          <div style={{ padding: "20px 5%", height: "calc(100% - 80px)", overflowY: "scroll" }}>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(1, minmax(200px, 1920))" }}>

            <Link exact to="/Enquiry" activeClassName="activeClicked">
                            <button  style={{ marginLeft: '10px' }} className="btn btn-info" >Back</button>
                          </Link>
                
               
             
              <div className="mt-5">
                <h4 className="font-weight-bold mb-3">Enquiry List</h4>
                <Form>
        <Row className="mb-3">
          <Col xs="auto">
            <Form.Group controlId="formItemsPerPage">
              <Form.Label>Items per Page</Form.Label>
              
              <Form.Control
                type="number"
               min={1}
                placeholder="Enter items per page"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>

                <CDBTable responsive>
                  <CDBTableHeader color="dark">
                    <tr>
                      <th>Enquiry ID</th>
                      <th>Enquiry No</th>
                      <th>EnquiryDate</th>
                      <th>StudentName</th>
                      <th>Reference</th>
                      <th>Parent's Name</th>
                      <th>Parent's Job</th>
                      <th>Address</th>
                      <th>City ID</th>
                      <th>State ID</th>
                      <th>Mobile No </th>

                      <th>School Name</th>
                      <th>School Timing</th>
                      <th>Enquiry Mode ID</th>
                      <th>Enquiry Type ID</th>
                      <th>Email</th>
                      <th>PrefferedTimingId</th>
                      <th>PrefferedBranchId</th>
                      <th>MCA_ID</th>
                      <th>FIDE ID</th>
                      <th>AICF ID</th>
                      <th>StudentPhoto</th>
                      <th>Birth Certificate</th>
                      <th>AddharCard</th>
                      <th></th>
                      <th></th>
                      <th></th>

                    </tr>
                  </CDBTableHeader>
                  <CDBTableBody>



                    { currentItems.map((getcate) => (

                      <tr key={getcate.Id}>
                        <td>{getcate.Id}</td>
                        <td>{getcate.EnquiryNo}</td>
                        <td>{(getcate.EnquiryDate.date).substring(0, 10)}</td>
                        <td>{getcate.StudentName}</td>
                        <td>{getcate.Reference}</td>
                        <td>{getcate.ParentsName}</td>
                        <td>{getcate.ParentsJob}</td>
                        <td>{getcate.Address}</td>

                        <td>{getcate.CityId}</td>
                        <td>{getcate.StateId}</td>
                        <td>{getcate.MobileNo}</td>

                        <td>{getcate.SchoolName}</td>
                        <td>{getcate.SchoolTiming}</td>
                        <td>{getcate.EnquiryModeId}</td>
                        <td>{getcate.EnquiryTypeId}</td>
                        <td>{getcate.EmailId}</td>
                        <td>{getcate.PrefferedTimingId}</td>
                        <td>{getcate.PrefferedBatchId}</td>
                        <td>{getcate.MCAId}</td>
                        <td>{getcate.FIDEId}</td>
                        <td>{getcate.AICFId}</td>
                        <td>{getcate.StudentPhoto}</td>
                        <td>{getcate.BirthCertificate}</td>
                        <td>{getcate.AddharCard}</td>

                    




                                
                                <td>
                            <button  onClick={()=>{ViewEnquirys(getcate.Id)}} style={{ marginLeft: '10px' }} className="btn btn-warning" ><FaEye /></button>
                        
                                </td>


                                <td>
                            <button  onClick={()=>{ UpdateEnquirys(getcate.Id)}} style={{ marginLeft: '10px' }} className="btn btn-success" ><TfiPencilAlt /></button>
                        
                                </td>
                          
                                
                                       <td>   <button type="DELETE"  onClick={(txt) =>    delEnquirys (getcate.Id)} className="btn btn-danger" ><RiDeleteBin5Fill /></button></td>
                      </tr>
                    ))}


                  </CDBTableBody>
                </CDBTable>

               

              </div>
            

            </div>
            {/* <Pagination>
             {Array.from({ length: Math.ceil(category.length / itemsPerPage) }, (_, index) => (
             <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => paginate(index + 1)}>
            {index + 1}
           </Pagination.Item>
        ))}
        </Pagination> */}
        <Row className="justify-content-center">
        <Col xs="auto">
          <Pagination>
            {Array.from({ length: Math.ceil(category.length / itemsPerPage) }, (_, index) => (
              <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => paginate(index + 1)}>
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </Col>
      
      </Row>
            <footer className="mx-auto my-3 text-center">
              <small>&copy; Microtech, 2023. All rights reserved.</small>
            </footer>
          </div>

        </div>
      </div>
    </div>
  );
};


export default EnquiryList;