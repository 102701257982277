import React from "react";
import { Header } from "./Navbar.style";
import { CDBNavbar } from "cdbreact";


const Navbar = () => {

  

	return (
        <Header style={{background:"#002336"}}>
          <CDBNavbar dark expand="md" scrolling className="justify-content-start">
            {/* <CDBInput type="search" size="md" hint="Search" className="mb-n4 mt-n3 input-nav"/> */}
             <h5 style={{display:'flex',marginLeft:'20px',font:'caption',fontFamily:'sans-serif',color:'red',textDecorationColor:'Highlight'}}>ANAYA'S CHESS CLUB</h5>
            <div className="ml-auto">
              
        
           

              {/* <img className="logouticon" alt="logoutImage" onClick={<Login/>} src="img/pane/logout.png" style={{alignItems:"end",justifyContent:'end',marginLef:'10px',width:"2rem",height:"2rem"}}/> */}
            
              <a
              href="/"
            className="text-decoration-none"
            style={{ color: "#54abe7" }}
              >
             
          
            </a>
                                                                                         
            </div>
          </CDBNavbar>
        </Header>
	);
}

export default Navbar;
