import { useState } from "react";


import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";
import axios from "axios";


export const Batch = () => {


  const [batchname, batchnamechange] = useState("");
  const [branch, branchchange] = useState("");
  const [mode, modechange] = useState("");
  const [type, typechange] = useState('');
  const [timing, timingchange] = useState('');
  const [fees, feeschange] = useState('');

  const postAPI = () => {

    let config = {
      method: 'post',
      mode: "cors",
      maxBodyLength: Infinity,
      url: "https://peapi.microtechsolutions.co.in/php/postbatchmst.php",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: {
        'ModeId': '3', mode,
        'BrachId': '2', branch,
        'BatchName': batchname,
        'TypeId': type,
        'Timing': timing,
        'Fees': fees,
      }
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);

      });

  }

  const deleteBatch = () => {
  
    let config = {
      method: 'post',
      mode: "cors",
      maxBodyLength: Infinity,
      url: "https://peapi.microtechsolutions.co.in/php/delbatchmst.php",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: {
        'Id':  '',
       
      }
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);

      });

  }




  return (
    <div className="d-flex">
      <div>
        <Sidebar />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
          height: "100vh",
          overflowY: "hidden",
        }}
      >
        <Navbar />
        <div style={{ height: "100%" }}>
          <div
            style={{
              padding: "20px 5%",
              height: "calc(100% - 64px)",
              overflowY: "scroll",
              background: "whitesmoke",
            }}
          >
            <div
              style={{
                display: "grid",
                background: "white",
                gridTemplateColumns: "repeat(1, minmax(200px, 1200px))",
              }}
            >
              <>


                <div class="page-wrapper" >
                  <div class="content container-fluid">

                    <div class="page-header" style={{ backgroundColor: '#0b6fa1' }}>
                      <div class="row align-items-center">
                        <div class="col">
                          <h3 class="page-title" style={{ color: "white", textAlign: 'center' }}>Batch</h3>

                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-12">
                        <div class="card">
                          <div class="card-body" style={{ backgroundColor: '#55a6cf' }}>
                            <form>
                              <div class="row">


                                <div class="col-12 col-sm-4">
                                  <div class="form-group local-forms">
                                    <label>Batch Name <span class="login-danger">*</span></label>
                                    <input required onChange={e => batchnamechange(e.target.value)} type="text" class="form-control" />
                                  </div>
                                </div>
                                <div class="col-12 col-sm-4">
                                  <div class="form-group local-forms">
                                    <label>Branch ID  <span class="login-danger">*</span></label>
                                    <select required className="form-control select" onChange={e => branchchange(e.target.value)}>
                                      <option>Selected Branch </option>
                                      <option>1 </option>
                                      <option>2</option>
                                      <option>3</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>
                                      Mode <span className="login-danger">*</span>
                                    </label>
                                    <select required className="form-control select" onChange={e => modechange(e.target.value)}>
                                      <option>Selected Mode </option>
                                      <option>1 </option>
                                      <option>2</option>
                                      <option>3</option>

                                    </select>
                                  </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="form-group local-forms">
                                    <label>
                                      Type <span className="login-danger">*</span>
                                    </label>
                                    <select required className="form-control select" onChange={e => typechange(e.target.value)}>
                                      <option>Selected Type </option>
                                      <option>1 </option>
                                      <option>2</option>
                                      <option>3</option>

                                    </select>
                                  </div>
                                </div>
                                <div class="col-12 col-sm-4">
                                  <div class="form-group local-forms">
                                    <label>Timing <span class="login-danger">*</span></label>
                                    <input required onChange={e => timingchange(e.target.value)} type="text" class="form-control" />
                                  </div>
                                </div>
                                <div class="col-12 col-sm-4">
                                  <div class="form-group local-forms">
                                    <label>Fees <span class="login-danger">*</span></label>
                                    <input required onChange={e => feeschange(e.target.value)} type="text" class="form-control" />
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="student-submit">
                                    <button onClick={postAPI} class="btn btn-primary">Submit</button>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="student-submit">
                                    <button onClick={deleteBatch} class="btn btn-danger">Delete</button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>






              </>



            </div>
            <footer className="mx-auto my-3 text-center">
              <small>&copy; Microtech, 2023. All rights reserved.</small>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};
