import React from "react";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,

  CDBSidebarMenuItem,
  
} from "cdbreact";
import { CiLogout } from "react-icons/ci";


import './Sidebar.css'; 
import { Link } from "react-router-dom";

import {Scrollbars} from "react-custom-scrollbars";


const Sidebar = () => {


  return (
    <div
      className={`app`}
      style={{ display: "flex", height: "100%", overflow: "scroll initial" }}
    >
      <CDBSidebar textColor="#fff" backgroundColor="#002336">
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
       
            <img alt="logo" src="img/LOGO/logochess.jpg" style={{width:"150px",height:"50px"}}/>
          

        </CDBSidebarHeader>
        <Scrollbars responsive style={{width:"100%",height:"100%"}}>
          <CDBSidebarContent className="sidebar-content">
            <CDBSidebarMenu>



              <Link exact to="/Home" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="home">Home</CDBSidebarMenuItem>
              </Link>
              
              


              <Link exact to="/enquiry" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="plus">Enquiry</CDBSidebarMenuItem>
              </Link>

              <Link exact to="/registration" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="book">Registration</CDBSidebarMenuItem>
              </Link>

              <Link exact to="/attendence" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="edit">Attendence</CDBSidebarMenuItem>
              </Link>



              <Link exact to="/fees" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="book">Fees</CDBSidebarMenuItem>
              </Link>



             
             
                <Link exact to="#" activeClassName="deactiveClicked">
                <CDBSidebarMenuItem icon="list">
                Master
                </CDBSidebarMenuItem>
                </Link>
             

              <Link
                exact
                to="/branch"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="th">Branch</CDBSidebarMenuItem>
              </Link>

              <Link
                exact
                to="/batch"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem icon="pen">Batch</CDBSidebarMenuItem>
              </Link>

              <Link exact to="/coach" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="pen">Coach</CDBSidebarMenuItem>
              </Link>

              <Link exact to="/subject" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="pen">Subject</CDBSidebarMenuItem>
              </Link>

              <Link exact to="/batchlist" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="list">BatchList</CDBSidebarMenuItem>
              </Link>
              <Link exact to="/type" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="list">Type</CDBSidebarMenuItem>
              </Link>
              <Link exact to="/listEnquiry" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="list">listEnquiry</CDBSidebarMenuItem>
              </Link>
         
            </CDBSidebarMenu>
            
            <CDBSidebarMenu></CDBSidebarMenu>
          </CDBSidebarContent>
     </Scrollbars>



        <CDBSidebarFooter style={{ textAlign: "center" }}>
      
          <div
            className="sidebar-btn-wrapper"
            style={{
              padding: "25px 5px",color:'red',MozUserFocus: 'normal'
            }}
          >
           <Link exact to="/" activeClassName="activeClicked">
                <button style={{background:"#D9468D",color:'white',borderColor:'CaptionText',borderRadius:'8px',boxSizing:"border-box"}} > <CiLogout />LogOut</button>
              </Link>
      
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </div>
  );
};

export default Sidebar;
